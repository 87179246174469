import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { BannerDto, CreateBannerDto, PageResultDto, PagingDto, UpdateBannerDto } from "@api/dto"


@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<BannerDto>>('/admin/v1/banner', { limit, offset })
  }

  async create(createBannerDto: CreateBannerDto) {
    return await this.httpService.post<BannerDto>('/admin/v1/banner', createBannerDto)
  }

  async update(id: string, updateBannerDto: UpdateBannerDto) {
    return await this.httpService.put<boolean>(`/admin/v1/banner/${id}`, updateBannerDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/banner/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/banner/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/banner/${id}/disable`)
  }
}