import { Injectable } from "@angular/core"

import { HttpService } from "../http.service"
import { CreatePromotionDto, FindPromotionDto, PageResultDto, PromotionDetailDto, PromotionDto, UpdatePromotionDto } from "@api/dto"


@Injectable()
export class PromotionService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindPromotionDto) {
    return await this.httpService.get<PageResultDto<PromotionDto>>('/admin/v1/promotion', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<PromotionDetailDto>(`/admin/v1/promotion/${id}`)
  }

  async create(createPromotionDto: CreatePromotionDto) {
    return await this.httpService.post<PromotionDto>('/admin/v1/promotion', createPromotionDto)
  }

  async update(id: string, updatePromotionDto: UpdatePromotionDto) {
    return await this.httpService.put<boolean>(`/admin/v1/promotion/${id}`, updatePromotionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/promotion/${id}`)
  }

  async updateThumbnail(id: string, fileId: string) {
    return await this.httpService.put<boolean>(`/admin/v1/promotion/${id}/thumbnail`, { fileId })
  }

  async sendNotification(id: string) {
    return await this.httpService.post<boolean>(`/admin/v1/promotion/${id}/notification`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/promotion/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/promotion/${id}/disable`)
  }

  async publish(id: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/promotion/${id}/publish`)
  }
}