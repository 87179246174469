import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, UserService, ProfileService, PromotionService, SettingService, PageService, AllowPhoneNumberService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { BannerService } from '../services/admin/banner.service'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    PromotionService,
    SettingService,
    PageService,
    AllowPhoneNumberService,
    BannerService,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    // window['serverURL'] = options.serverURL

    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
